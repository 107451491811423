














































































































































































































































































































































































































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator';
import PigeonLogo from '@/components/PigeonLogo.vue';
import {Action, Getter, Mutation} from 'vuex-class';
import {auth} from '@/plugins/firebase.init';
import {parseURL} from '@/utils/helpers';
import AvatarWithStatus from '@/components/AvatarWithStatus.vue';
import {businessStore} from '@/store/modules/business/businessStore';
import {applicationStore} from '@/store/modules/application';
import {mixins} from 'vue-class-component';
import Notifications from '@/components/mixins/Notifications';
import GpNotification from '@/components/custom/Notification.vue';
import TextFieldEmail from '@/components/profile/privacy-security/TextFieldEmail.vue';
import ValidationRules from '@/plugins/validations';
import {verification} from '@/data/firebase';
import {onSnapshot} from 'firebase/firestore';
import {isSignInWithEmailLink, signInWithEmailLink, updatePassword} from 'firebase/auth';
import TagManager from '@/mixins/TagManager';

const namespace = 'auth';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
]);

@Component({
  name: 'auth',
  components: {TextFieldEmail, GpNotification, AvatarWithStatus, PigeonLogo}
})
export default class Auth extends mixins(Notifications, TagManager) {
  @Prop() step?: string;

  steps: any = {
    signUp: 'sign-up',
    verifyEmail: 'verify-email',
    complete: 'complete',
    signIn: 'sign-in',
    action: 'action',
    forgotPassword: 'forgot-password',
    resetPassword: 'reset-password',
    setPassword: 'set-password',
    checkEmail: 'check-email',
    checkLinkEmail: 'check-link-email',
    passwordResetComplete: 'reset-complete',
    createProfile: 'create-profile',
    profilePhoto: 'profile-photo'
  };

  email: string = '';
  fullName: string = '';
  businessName: string = '';
  position: string = '';
  phone: string = '';
  password: string = '';
  confirmPassword: string = '';

  profilePhotoFile: File | null = null;
  profilePhotoUri: any | null = null;

  show: boolean = false;
  localError: string | null = null;
  localLoading: boolean = false
  validateForm: boolean = false
  emailExistError: boolean = false

  @Getter('loading', {namespace}) loading;
  @Getter('error', {namespace}) error;
  @Getter('oobCode', {namespace}) oobCode;

  @Mutation('saveOobCode', {namespace}) saveOobCode;
  @Mutation('resetOobCode', {namespace}) resetOobCode;

  @Action('sendEmailVerificationLink', {namespace}) sendEmailVerificationLink;
  @Action('checkEmailVerification', {namespace}) checkEmailVerification;
  @Action('completeEmailVerification', {namespace}) completeEmailVerification;
  @Action('completeRegistration', {namespace}) completeRegistration;
  @Action('continueWithGoogle', {namespace}) continueWithGoogle;
  @Action('requestPasswordReset', {namespace}) requestPasswordReset;
  @Action('verifyPasswordResetCode', {namespace}) verifyPasswordResetCode;
  @Action('resetPassword', {namespace}) resetPassword;

  get signingEmailError() {
    return applicationStore.signingEmailError;
  }

  get signingPwdError() {
    return applicationStore.signingPwdError;
  }

  get signing() {
    return applicationStore.signing;
  }

  get errorMessage() {
    return businessStore.error || this.error || this.localError;
  }

  get passwordsNotEqual() {
    return this.password !== this.confirmPassword;
  }

  get year() {
    return new Date().getFullYear();
  }

  get signUpEmailLabel() {
    return !!this.email ? 'Email' : 'Example: name@email.com'
  }

  get isResetPasswordValidate() {
    return this.validateForm
  }

  checkEmailExist() {
    return !this.emailExistError || 'This email is already registered. Try another one.'
  }

  async onGetStarted() {
    this.pushDataLayout('get_started_button_activated')
    const form: any = this.$refs.formEmailAddress;
    if (form.validate()) {
      const verificationId: string | null = await this.sendEmailVerificationLink(this.email)
      if (!verificationId) {
        return
      }
      await this.$router.push({name: 'auth', params: {step: this.steps.verifyEmail}});
      const onNext = (snapshot) => {
        if (!snapshot.exists) {
          this.showInfo('Verified. Please sign-in to continue')
          onSnapshotListener()
          this.$router.push({
            name: 'auth',
            params: {step: this.steps.signIn}
          });
        }
      };
      const onError = (error) => {
        this.showIssue(error.message)
        onSnapshotListener()
      };
      const onSnapshotListener = onSnapshot(verification(verificationId), onNext, onError);
    }
  }

  @Watch('errorMessage')
  onErrorChanged(after: string, before: string) {
    if (after === before || after === null) {
      return
    }
    if (after === 'user already exists' && this.step === this.steps.signUp) {
      this.emailExistError = true
      const form: any = this.$refs.formEmailAddress
      form.validate()
    } else {
      this.showIssue(after)
    }
  }

  @Watch('email')
  onEmailChanged() {
    const form: any = this.$refs?.formForgotPassword
    this.validateForm = form ? form.validate() : false
    this.emailExistError = false
  }

  @Watch('step')
  onStepChanged(after: string) {
    if (after === 'sign-up') this.pushDataLayout('signup_screen_opened')
    if (after === 'verify-email') this.pushDataLayout('check_email_screen_opened')
    if (after === 'complete') this.pushDataLayout('create_account_screen_opened')
    if (after === 'forgot-password') {
      this.validateForm = ValidationRules.required(this.email) === true &&
          ValidationRules.validEmail(this.email) === true &&
          ValidationRules.doubleDot(this.email) === true
    }
  }

  onSignIn() {
    this.$router.push({name: 'auth', params: {step: this.steps.signIn}});
  }

  onSignUp() {
    this.$router.push({name: 'auth', params: {step: this.steps.signUp}});
  }

  onReenterEmail() {
    this.pushDataLayout('re_enter_email_link_activated')
    this.$router.push({name: 'auth', params: {step: this.steps.signUp}});
  }

  passwordConfirmationRule() {
    return this.password === this.confirmPassword || 'Password must match'
  }

  get emptyForm() {
    return this.businessName.length === 0
        || this.fullName.length === 0
        || this.password.length === 0
        || this.confirmPassword.length === 0
  }

  async onCompleteRegistration() {
    this.pushDataLayout('create_account_button_activated')
    const {fullName, businessName, password} = this;
    const form: any = this.$refs.formCompleteRegistration;
    const valid = form.validate();
    if (!valid) {
      this.showIssue('Invalid data entered')
      return
    }
    const docId = this.$route.query['docId'];
    const skey = this.$route.query['skey'];
    let ok: boolean = await this.completeEmailVerification({docId, skey});
    if (!ok) {
      return
    }

    const completed: boolean = await this.completeRegistration({fullName, businessName, password});
    if (!completed) {
      return
    }

    this.localLoading = true
    ok = await this.signInByEmail(window.localStorage.getItem('registrationEmail') || '');
    if (!ok) {
      this.showIssue('Failed to sign-in')
      this.localLoading = false
      return
    }
    window.localStorage.removeItem('registrationEmail')
    await this.$router.push({name: 'get-started'});
    this.localLoading = false
  }

  signInByEmail(email: string) {
    return applicationStore.signIn({email, password: this.password});
  }

  async validateAndSignIn() {
    const formSignIn: any = this.$refs.formSignIn;
    if (formSignIn.validate()) {
      const ok = await this.signInByEmail(this.email);
      if (ok) {
        if (this.$route.query?.redirect) {
          await this.$router.push(this.$route.query.redirect as string)
        } else {
          await this.$router.push({name: 'get-started'});
        }
      }
    }
  }

  onForgotPassword() {
    this.password = '';
    this.confirmPassword = '';
    this.$router.push({name: 'auth', params: {step: this.steps.forgotPassword}})
  }

  async onSendResetPasswordLink() {
    const form: any = this.$refs.formForgotPassword;
    if (form.validate() && await this.requestPasswordReset(this.email)) {
      await this.$router.push({name: 'auth', params: {step: this.steps.checkEmail}});
    }
  }

  async onResetPassword() {
    const oobCode = this.$route.query['oobCode'];
    if (this.oobCode !== oobCode) {
      this.showIssue('Password reset code is invalid')
      await this.$router.push({name: 'auth'})
      return
    }
    const form: any = this.$refs.formResetPassword;
    if (!form.validate()) {
      this.showIssue('Invalid form data')
      return
    }
    const result = await this.resetPassword({oobCode, password: this.confirmPassword});
    if (!result) {
      this.showIssue('Failed to reset password')
      return
    }
    this.password = '';
    this.confirmPassword = '';
    this.resetOobCode()
    await this.$router.push({name: 'auth', params: {step: this.steps.passwordResetComplete}});
  }

  async onSetPassword() {
    const businessId = this.$route.query.bid as string;
    const form: any = this.$refs.formSetPassword;
    if (form.validate()) {
      try {
        const user = auth.currentUser!;
        await updatePassword(user, this.confirmPassword);
        this.password = '';
        this.confirmPassword = '';
        const ok = await businessStore.clearInvite({businessId, email: user.email!!});
        if (ok) {
          await this.$router.push({name: 'get-started'});
        }
      } catch (e: any) {
        this.localError = e.message;
      }
    }
  }

  async onContinueWithGoogle() {
    this.pushDataLayout('google_auth_activated')
    this.continueWithGoogle()
  }

  onChooseProfilePhoto(event) {
    const files = event.target.files || event.dataTransfer.files;
    this.profilePhotoFile = files[0];
    if (!!this.profilePhotoFile) {
      console.log(this.profilePhotoFile.name);
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.profilePhotoUri = e.target.result || null;
      };
      reader.readAsDataURL(this.profilePhotoFile);
    }
  }

  // deprecated
  onContinueToProfilePhoto() {
    const form: any = this.$refs.formCreateProfile;
    if (form.validate()) {
      this.$router.push({
        name: 'auth',
        params: {step: this.steps.profilePhoto},
        query: this.$route.query
      });
    }
  }

  async onCompleteCreateProfile() {
    const form: any = this.$refs.formCreateProfile;
    if (form.validate()) {
      const uid = this.$route.query.uid as string;
      const role = this.$route.query.role as string;
      const bid = this.$route.query.bid as string;
      const bn = this.$route.query.bn as string;
      const {fullName, password, position, phone, profilePhotoFile, email} = this;
      this.localLoading = true
      const user = {
        id: uid,
        name: fullName,
        email,
        password,
        position,
        phoneNumber: phone,
        role: parseInt(role),
        business: {id: bid, name: bn},
        autoCreateContact: false,
        profilePhoto: profilePhotoFile,
        sendInvite: false
      };
      let ok = await businessStore.createAssociateProfile(user);
      if (ok) {
        ok = await businessStore.clearInvite({businessId: user.business.id, email: user.email});
        if (ok) {
          await this.$router.push({name: 'get-started'});
        }
      }
      this.localLoading = false
    }
  }

  async onCheckLinkEmail() {
    const form: any = this.$refs.formCheckLinkEmail;
    if (!form.validate()) {
      return;
    }
    const url = window.location.href;
    const parsed = parseURL(url);
    const continueUrlParams = parseURL(decodeURIComponent(parsed.queryObject['continueUrl'])).queryObject;
    const mode = continueUrlParams['mode'];
    try {
      const result = await signInWithEmailLink(auth, this.email, url);
      const user = result.user;
      if (!!user && !!user.uid) {
        window.localStorage.setItem('emailForSignUp', this.email);
        continueUrlParams['uid'] = user.uid;
        if (mode === 'signUp') {
          await this.$router.push({
            name: 'auth',
            params: {step: this.steps.createProfile},
            query: continueUrlParams
          });
          return
        }
        if (mode === 'signInOnly') {
          await this.$router.push({
            name: 'auth',
            params: {step: this.steps.setPassword},
            query: continueUrlParams
          });
        }
      }
    } catch (e: any) {
      console.log(e);
      this.localError = e.message;
    }
  }

  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (vm.step === 'sign-up') this.pushDataLayout( 'signup_screen_opened')
      const isActionStep = vm.step === vm.steps.action;
      const url = window.location.href;
      if (isActionStep && isSignInWithEmailLink(auth, url)) {
        await applicationStore.signOut()
        return await vm.$router.push({
          name: 'auth',
          params: {step: vm.steps.checkLinkEmail},
          query: to.query
        });
      }
      const actionMode = to.query['mode'];
      if (isActionStep && actionMode === 'verifyEmail') {
        await applicationStore.signOut();
        const docId = to.query['docId'];
        const skey = to.query['skey'];
        const ok: boolean = await vm.checkEmailVerification({docId, skey});
        if (!ok) {
          await vm.$router.push({
            name: 'auth',
            params: {step: vm.steps.signIn}
          });
          return
        }
        await vm.$router.push({
          name: 'auth',
          params: {step: vm.steps.complete},
          query: to.query
        });
        return
      }
      if (isActionStep && actionMode === 'resetPassword') {
        await applicationStore.signOut();
        const oobCode: string = to.query['oobCode'];
        if (await vm.verifyPasswordResetCode(oobCode)) {
          vm.saveOobCode(oobCode)
          await vm.$router.push({
            name: 'auth',
            params: {step: vm.steps.resetPassword},
            query: {oobCode: vm.oobCode}
          });
        } else {
          vm.showIssue('Failed to verify password reset code')
        }
        return
      }
      if (!!auth.currentUser) {
        if (window.localStorage.getItem('emailForSignUp')) {
          window.localStorage.removeItem('emailForSignUp');
          await applicationStore.signOut();
        }
        if (actionMode === 'signUp') {
          await auth.signOut()
          return await vm.$router.push({name: 'auth', params: {step: 'sign-in'}})
        }
        return await vm.$router.push({name: 'get-started'});
      }
    })
  }

  mounted() {
    document.body.setAttribute('style', 'width: auto; min-width: 0;');
  }

  beforeDestroy() {
    document.body.removeAttribute('style');
  }
}
